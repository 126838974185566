(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("antd"), require("React"));
	else if(typeof define === 'function' && define.amd)
		define("InputAfter", ["antd", "React"], factory);
	else if(typeof exports === 'object')
		exports["InputAfter"] = factory(require("antd"), require("React"));
	else
		root["InputAfter"] = factory(root["antd"], root["React"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__) {
return 